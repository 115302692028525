const financeAdUnits = {
  'finance.themarker.com.banner1': {
    adUnitCode: 'Finance.TheMarker.com.Banner1',
    interstitial: false,
    sizes: [ [ 300, 250, ], [ 728, 90, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.banner2': {
    adUnitCode: 'Finance.TheMarker.com.Banner2',
    interstitial: false,
    sizes: [ [ 300, 250, ], [ 728, 90, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.banner3': {
    adUnitCode: 'Finance.TheMarker.com.Banner3',
    interstitial: false,
    sizes: [ [ 160, 600, ], [ 300, 250, ], [ 728, 90, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.billboard': {
    adUnitCode: 'Finance.TheMarker.com.Billboard',
    interstitial: false,
    sizes: [ [ 970, 90, ], [ 960, 150, ], [ 468, 60, ], [ 970, 250, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.fujileft': {
    adUnitCode: 'Finance.TheMarker.com.FujiLeft',
    interstitial: false,
    sizes: [ [ 212, 144, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.fujiright': {
    adUnitCode: 'Finance.TheMarker.com.FujiRight',
    interstitial: false,
    sizes: [ [ 212, 144, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.halfpage': {
    adUnitCode: 'Finance.TheMarker.com.HalfPage',
    interstitial: false,
    sizes: [ [ 300, 600, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.inlistnative': {
    adUnitCode: 'Finance.TheMarker.com.InListNative',
    interstitial: false,
    sizes: [ [ 124, 93, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.interstitial': {
    adUnitCode: 'Finance.TheMarker.com.interstitial',
    interstitial: true,
    sizes: [ [ 2, 4, ], [ 360, 120, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.kodakleft': {
    adUnitCode: 'Finance.TheMarker.com.KodakLeft',
    interstitial: false,
    sizes: [ [ 212, 144, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.kodakright': {
    adUnitCode: 'Finance.TheMarker.com.KodakRight',
    interstitial: false,
    sizes: [ [ 212, 144, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.native1': {
    adUnitCode: 'Finance.TheMarker.com.Native1',
    interstitial: false,
    sizes: [ [ 212, 145, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.native2': {
    adUnitCode: 'Finance.TheMarker.com.Native2',
    interstitial: false,
    sizes: [ [ 212, 145, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.native3': {
    adUnitCode: 'Finance.TheMarker.com.Native3',
    interstitial: false,
    sizes: [ [ 212, 145, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.native4': {
    adUnitCode: 'Finance.TheMarker.com.Native4',
    interstitial: false,
    sizes: [ [ 212, 145, ], ],
    targetUser: 'all',
  },
  'finance.themarker.com.stripbottom': {
    adUnitCode: 'Finance.TheMarker.com.StripBottom',
    interstitial: false,
    sizes: [ [ 320, 50, ], [ 468, 60, ], ],
    targetUser: 'all',
  },
};

export default financeAdUnits;
