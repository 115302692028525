// @flow
import React from 'react';
import Head from 'next/head';


import type { Node, } from 'react';

function Taboola(): Node {
  return (
    <Head>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'page_view', id: 1383653});
            !function (t, f, a, x) {
              if (!document.getElementById(x)) {
                t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
              }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/1383653/tfa.js',
            'tb_tfa_script');
          `,
        }}
      />
      <noscript>
        <img
          src="https://trc.taboola.com/1383653/log/3/unip?en=page_view"
          width="0"
          height="0"
          style={{ display: 'none', }}
        />
      </noscript>
    </Head>
  );
}

export default Taboola;
