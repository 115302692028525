// @flow
import React, { Fragment, } from 'react';
import type { Node, } from 'react';

import { FacebookPixel, TwitterPixel, OutbrainPixel, Taboola, GoogleAdsPixel, } from '@haaretz/htz-components';
import GoogleAnalytics from './scripts/GoogleAnalytics';

function Scripts(): Node {
  return (
    <Fragment>
      <GoogleAnalytics
        trackers={[
          { id: 'UA-3574867-1', name: 'theMarker', },
          { id: 'UA-3574867-15', name: 'finance', },
        ]}
      />
      <FacebookPixel />
      <TwitterPixel />
      <OutbrainPixel />
      <Taboola />
      <GoogleAdsPixel />
    </Fragment>
  );
}

export default Scripts;
