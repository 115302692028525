/* global window */
// @flow
import React, { useEffect, useState, useRef, } from 'react';
import ReactGA from 'react-ga';
import gql from 'graphql-tag';
import { Query, } from '@haaretz/htz-components';

import type { Node, } from 'react';

const GET_CANONICAL = gql`
  query GetCanonical {
    title @client
  }
`;

type Tracker = {
  id: string,
  name: string,
  options?: Object,
}

type Props = {
  trackers: Tracker[],
}

function GoogleAnalytics({ trackers, }: Props): Node {
  const [ shouldRender, setShouldRender, ] = useState(false);

  const urlRef = useRef();
  const titleRef = useRef();

  useEffect(
    () => {
      ReactGA.initialize(
        trackers.map(({ id, name, options, }) => ({
          trackingId: id,
          gaOptions: {
            name,
            ...(options || {}),
          },
        }))
      );
    },
    [ trackers, ]
  );

  useEffect(() => { setShouldRender(true); }, []);

  useEffect(
    () => {
      const page = urlRef.current || window.location.pathname + window.location.search;
      ReactGA.set({
        page,
      });
      ReactGA.pageview(page, trackers.map(({ name, }) => name), titleRef.current);
    },
    [ trackers, ]
  );

  return shouldRender ? (
    <Query
      query={GET_CANONICAL}
    >
      {({ loading, error, data, }) => {
        if (data) {
          urlRef.current = window.location.pathname + window.location.search;
          titleRef.current = data.title;
        }
        return null;
      }}
    </Query>
  )
    : null;
}

export default GoogleAnalytics;
