// @flow
import * as React from 'react';
import financeAdUnits from './financeAdUnits';

type PropsType = {
  children: ChildrenArray<any>,
};

const AdUnitsContext = React.createContext({});

export function AdUnitsContextProvider({ children, }: PropsType) {
  return (
    <AdUnitsContext.Provider value={financeAdUnits}>
      {children}
    </AdUnitsContext.Provider>
  );
}

export default function useAllAdUnits() {
  return React.useContext(AdUnitsContext);
}
