// @flow
import React, { Fragment, } from 'react';

import type { Node, } from 'react';

function GoogleAdsPixel(): Node {
  return (
    <Fragment>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-955121318" />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-955121318');
          `,
        }}
      />
    </Fragment>
  );
}

export default GoogleAdsPixel;
