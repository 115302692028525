// @flow
import React, { Fragment, useRef, useEffect, } from 'react';
import type { ChildrenArray, } from 'react';
import Head from 'next/head';
import { createApp, UserInjector, doStat, userScheme, } from '@haaretz/htz-components';
import { withApollo, } from '@haaretz/app-utils';
import { StyleProvider, } from '@haaretz/fela-utils';
import { tmTheme, } from '@haaretz/tm-theme';

import { AdUnitsContextProvider, } from '../components/AdManager/AdUnitsProvider';
import styleRenderer from '../components/styleRenderer/styleRenderer';
import Scripts from '../components/Scripts/Scripts';

const initialState = () => ({
  pageType: 'finance',
  isOsakaDisplayed: false,
  scriptsToRender: null,
  isReadyToRenderExternals: true,
  canonicalUrl: '',
  title: '',
  htzUser: userScheme,
  articleType: null,
});

type Props = {
  children: ChildrenArray<Node> | Node,
  url: {
    query: {
      section: string,
      assetId: string,
    },
    pathname: string,
  },
};

function AppWrapper({ children, url: { query: { section, assetId, }, pathname, }, }: Props) {
  const biReq = user => {
    const pageType = assetId
      ? 'quote'
      : section === '/'
        ? 'home'
        : 'section';
    doStat({ user, pageType, });
  };

  const isInitialMount = useRef(true);
  const userRef = useRef();

  useEffect(
    () => {
      if (!isInitialMount.current) {
        biReq(userRef.current);
      }
      else {
        isInitialMount.current = false;
      }
    },
    /* eslint-disable-next-line */
    [ pathname, ]
  );

  return (
    <Fragment>
      <Head>
        <meta
          name="google-site-verification"
          content="-3Rs25_Tuwid8njQF72cVtAcYAxGbYhXewg5Y4ppV_4"
        />
      </Head>
      {/* <Dfp section={section} assetId={assetId} /> */}
      <UserInjector
        onUserReady={user => {
          userRef.current = user;
          biReq(user);
        }}
      />
      <StyleProvider renderer={styleRenderer} theme={tmTheme}>
        <AdUnitsContextProvider>
          {children}
        </AdUnitsContextProvider>
      </StyleProvider>
      <Scripts />
    </Fragment>
  );
}

export default withApollo({ ssr: true, })(createApp(null, AppWrapper), initialState);
